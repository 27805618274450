<template>
  <div class="c-box-rwd-transform">
    <slot name="header"> </slot>
    <ul class="box-list">
      <li class="box-list__item" v-for="d of data" :key="d.id">
        <div class="box-list__item__box">
          <slot :data="d" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

// 手機平板滑動、電腦正常排列
export default defineComponent({
  name: 'PopularBoxRwdTransfrom',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
.c-box-rwd-transform {
  box-sizing: border-box;
  padding-left: 20px;
  width: 100%;
}
.box-list {
  @include flex();
  white-space: nowrap;
  overflow: auto;
  box-sizing: border-box;
  padding-right: 20px;

  &::-webkit-scrollbar {
    height: 0;
  }

  &__item {
    flex: none;
    width: 290px;
    white-space: normal;

    & + & {
      margin-left: 14px;
    }

  }
}

@media screen and (min-width: $mobile) {
  .c-box-rwd-transform {
    padding-left: calc((100% - 90%)/2);
    margin: 0 auto;
  }

  .box-list {
    padding-right: calc((100% - 90%)/2);
  }
}

@media screen and (min-width: $tablet) {
  .c-box-rwd-transform {
    padding-left: 0;
  }
  .box-list {
    flex-wrap: wrap;
    // width: 100%;
    width: auto;
    margin: 0 -15px;
    padding-right: 0px;

    &__item {
      flex: none;
      width: 25%;
      box-sizing: border-box;
      padding: 0 15px;
      // margin: 0 7px;

      & + & {
        margin-left: 0px;
      }
    }
  }
}
</style>
