<template>
  <div class="c-playlists-box item-hover--bottom" @click="toPlaylistsInfo">
    <div class="playlists-box">
      <img class="playlists-box__img" src="@/assets/explore/playlists_img.jpg" />
      <div class="playlists-box__overlay"></div>
      <p class="playlists-box__learn-more">了解更多</p>
    </div>
    <p class="playlists-title">{{ data.title }}</p>

  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PlaylistsBoxNormal',
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '12321',
        img: '',
        title: 'Advertising - Christmas in July',
      }),
    },
  },
  setup(props) {
    const router = useRouter();

    const toPlaylistsInfo = () => {
      router.push({
        name: 'explore-playlists-info',
        query: {
          id: props.data.id,
          title: props.data.title,
        },
      });
    };

    return {
      toPlaylistsInfo,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-playlists-box {
  @include padding(10px 10px 20px);
  border-radius: 5px;
  background-color: $c-white;
}
.playlists-box {
  // @include padding(0 10px);
  position: relative;

  &:hover {
    .playlists-box__overlay {
      display: inline-block;
      background-color: rgba($c-black, 0.5);
      opacity: 1;
    }

    .playlists-box__learn-more {
      opacity: 1;
    }
  }

  &__img {
    width: 100%;
    vertical-align: bottom;
    border-radius: 5px;
  }

  &__overlay {
    @include position(tl, 0, 0);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
  }

  &__learn-more {
    width: 100px;
    display: inline-block;
    text-align: center;
    @include position(center);
    @include font-style($c-white, 12, 400, 0.8px);
    @include padding(0 0 0 15px);
    opacity: 0;
    transition: 0.3s;

    &::before {
      content: '';
      @include circle(6px);
      @include position(tl, 60%, 0);
      transform: translateY(-50%);
      display: inline-block;
      background-color: $c-white;
    }
  }
}

.playlists-title {
  margin-top: 12px;
  @include font-style($c-black, 16, bold, 0.8px);
}

@media screen and (min-width: $tablet) {
  .c-playlists-box {
    @include padding(10px 10px 20px);
  }
}
</style>
